// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bD_k4";
export var caseStudyBackground__lineColor = "bD_kW";
export var caseStudyHead = "bD_k6";
export var caseStudyHead__imageWrapper = "bD_kT";
export var caseStudyProjectsSection = "bD_k5";
export var caseStudyQuote__bgLight = "bD_k2";
export var caseStudyQuote__bgRing = "bD_k1";
export var caseStudyVideoReview = "bD_l2";
export var caseStudyVideoReview__bgRing = "bD_l3";
export var caseStudyVideo__ring = "bD_k8";
export var caseStudy__bgDark = "bD_kS";
export var caseStudy__bgLight = "bD_kR";
export var caseStudy__bgLightReverse = "bD_l1";